//  Geographical constants
export const UNITED_STATES = 'US';
export const OVERSEAS_TERRITORIES = ['AA', 'AE', 'AP', 'AS', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'VI'];
export const CANADA = 'CA';
export const country2StateProvince = (country: string, state: string, province: string) => {
    if (country === UNITED_STATES) {
        return state;
    }

    if (country === CANADA) {
        return province;
    }

    return '';
};
