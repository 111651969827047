import { computed, makeObservable, observable, reaction } from 'mobx';
import { EventMessage, EventMessageUtils, InteractionStatus, PopupRequest, PublicClientApplication } from '@azure/msal-browser';

import { loginOrSignUpRequest, loginRequest, msalConfig, resetPassword, signUpRequest } from '../config/authConfig';

interface BearerTokenResponse {
    expiresOn: any,
    accessToken: string,
}

class MsalStore {
    private readonly _instance: PublicClientApplication;
    private readonly _scopes: Array<string>;

    private _status: InteractionStatus | null = InteractionStatus.None;
    private _access: BearerTokenResponse | null = null; // technically, it's an "AuthResponse" interface but that's internal to MSAL and I can't touch it

    working: boolean = false;
    user: any = null;

    constructor() {
        this._instance = new PublicClientApplication(msalConfig);
        this._scopes = loginRequest.scopes.slice();

        // in a perfect world, I'd makeObservable the properties inside the MSAL instance, but, as I don't know how MSAL is using them internally,
        // that'd be a risky play (truthy checks with Proxy objects are odd, for example)
        // the safest bet is to keep+copy into local the minimum of values needed, and update them from MSAL
        makeObservable(this, {
            working: observable,
            user: observable,
            inProgress: computed,
            isLoggedIn: computed,
        });

        this._instance.addEventCallback((evt: EventMessage) => {
            // full list of events:
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#table-of-events

            this._status = EventMessageUtils.getInteractionStatusFromEvent(evt);
        });

        // prime local observables from MSAL
        this.user = this.getUser();
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    get instance() {
        return this._instance;
    }

    get scopes() {
        return this._scopes;
    }

    get status() {
        return this._status;
    }

    get inProgress() {
        //return this._status !== InteractionStatus.None;
        return this.working;
    }

    get isLoggedIn() {
        // .user is a ProxyObject that _always_ exists - it just might proxy to a null value :\
        return Object.keys({...this.user}).length > 0;
    }

    get isLoggedOut() {
        return !this.isLoggedIn;
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    private getUser() {
        // MSAL seems to persist accounts set as active
        let acct = this._instance.getActiveAccount();

        if (acct) {
            return acct;
        }

        // if it ain't active, get the (hopefully) only person logged in + set as active
        acct = this._instance.getAllAccounts()[0];

        if (acct) {
            this.instance.setActiveAccount(acct);
            return acct;
        }

        // no one logged in, no user :shrug:
        return null;
    }

    async getToken() {
        return new Promise(async (resolve) => {

            if (!this.isLoggedIn) {
                return resolve(null);
            }

            if (this._access) {
                // if the bearer token was previously fetched...
                const now = (new Date()).getTime();
                const end = (this._access.expiresOn).getTime();

                // ..and we're more than five minutes away of the expiration of said token
                if ((end - now) > (1000 * 60 * 5)) {
                    // return JWT (via resolve; the "return" is to early-exit)
                    return resolve(this._access.accessToken);
                }
            }

            // glancing at the code, this configures performance metering, message brokering, and some cache management
            // idk precisely - it's internal to MSAL, but whoa does it get upset if you try and call functions w/o this being done first
            await this._instance.initialize();

            // if no token or the time is <= five minutes to expiration,
            // go fetch a new one, save locally, and return the fresh JWT
            const response = await this._instance.acquireTokenSilent({scopes: this.scopes});

            this._access = {
                expiresOn: response.expiresOn,
                accessToken: response.accessToken
            };

            resolve(this._access.accessToken);
        });
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    handleLogin() {
        this.openPopup(loginRequest);
    }

    handleSignUp() {
        this.openPopup(signUpRequest);
    }

    handleLoginOrSignUp() {
        this.openPopup(loginOrSignUpRequest);
    }

    handlePasswordReset() {
        this.openPopup(resetPassword);
    }

    private async openPopup(config: PopupRequest) {
        this.working = true;

        // previously, "redirectUri" defaulted to "window.location.origin" - at some point (unsure which version) it was switched to "window.location.href"
        // that's a major PITA b/c with how we're using the AAD (as a SPA backed by OAuth2.0), if the URI does not _exactly_ match one of the "Redirect URIs" specified
        // in the "nautilus" app registration, the popup fails to load
        const redirectUri = window.location.origin;

        // @azure/msal-browser/src/interaction_client/PopupClient.acquireTokenPopupAsync()
        //      ||
        //      \/
        // @azure/msal-browser/src/interaction_client/StandardInteractionClient.initializeAuthorizationRequest()
        //      ||
        //      \/
        // @azure/msal-browser/src/interaction_client/BaseInteractionClient.getRedirectUri()

        // this is almost certainly redundant, but do it anyway
        await this._instance.initialize();

        this._instance.loginPopup({
            ...config,
            redirectUri,
        }).then((response) => {
            // the response to a successful login is actually a Bearer token object
            // can save all those goods locally in preparation for future fetch() calls
            this._access = {
                expiresOn: response.expiresOn,
                accessToken: response.accessToken
            };

            this.user = this.getUser();

        }).catch((err) => {
            console.warn(err);
        }).finally(() => {
            this.working = false;
        });
    }

    async handleLogout() {
        this.working = true;

        await this._instance.initialize();

        this._instance.logoutPopup({
            //mainWindowRedirectUri: '/', // redirects the top level app after logout
        }).then(() => {
            // the MSAL logout flow automatically clears user cache data within itself
            // this clears *our* copy of it, the one Mobx is looking at
            this.user = null;

        }).catch((err) => {
            console.warn(err);
        }).finally(() => {
            this.working = false;
        });
    }

    async handleLogoutRedirect() {
        await this._instance.initialize();
        await this._instance.logoutRedirect();
    }

    addReactionToStatusChange(onLogIn: Function, onLogOut: Function) {
        // run functions to load / clear data in other mobx stores when the auth status of the user changes
        // e.g. on login, load bookmarks
        // https://mobx.js.org/reactions.html#reaction
        reaction(
            () => this.user,
            (userNow, userThen) => {
                if (userNow && !userThen) {
                    onLogIn();

                } else if (!userNow && userThen) {
                    onLogOut();

                } else {
                    // "same as it every was"
                }
            },
            {
                // run after a short delay, post-init of reaction; small buffer to let things "settle"
                fireImmediately: true,
                delay: 25 // < *might* need adjustment
            }
        );
    }
}

export const msalStore = new MsalStore();