import { action, computed, makeObservable, observable } from 'mobx';

import AccountApi from '../api/AccountApi';
import { FLAT_RATE_SHIPPING_COUNTRIES } from '../constants/checkoutConstants';
import { OVERSEAS_TERRITORIES, UNITED_STATES } from '../constants/geographies';
import { normalShipping, ShippingMethod } from '../model/ShippingMethod';
import { Status } from '../types/enum/Status';
import GeolocationStore from './GeolocationStore';
import Address from '../model/Address';

import { msalStore } from '../storeSingleton/msalStore';

export default class AddressStore {

    addresses: Address[] = [];
    shippingMethods: ShippingMethod[] = [];

    shipping: Address | null = null;
    billing: Address | null = null;
    accountNumber: string | null = null;

    domesticShippingMethod: ShippingMethod | null = null;
    internationalShippingMethod: ShippingMethod | null = null;

    status = {
        loadingAddresses: Status.initial,
    };

    addressSelectionDialogOpen: boolean = false;

    geolocationStore: GeolocationStore;
    accountApi: AccountApi;

    constructor(accountApi: AccountApi, geolocationStore: GeolocationStore) {
        makeObservable<AddressStore>(this, {
            status: observable,
            isLoading: computed,

            addresses: observable,
            shipping: observable,
            billing: observable,

            shippingAddresses: computed,
            shippingAddress: computed,
            billingAddresses: computed,
            billingAddress: computed,
            accountNumber: observable,
            shippingCountry: computed,
            domesticShippingCountry: computed,

            domesticShippingMethod: observable,
            internationalShippingMethod: observable,

            addressSelectionDialogOpen: observable,

            flatRateShippingCountry: computed,

            toggleAddressSelectionDialog: action,

            fetchAddresses: action,
            createAddress: action,
            updateAddress: action,
            deleteAddress: action,

            fetchShippingMethods: action,
        });

        this.accountApi = accountApi;
        this.geolocationStore = geolocationStore;

        msalStore.addReactionToStatusChange(
            () => {
                this.fetchAddresses();
            },
            () => {
                this.addresses = [];
            }
        );

        this.fetchShippingMethods();

        // this allows inspection of the mobx store w/o needing a breakpoint
        // @ts-ignore
        window.addressStore = this;
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    fetchAddresses() {
        this.status.loadingAddresses = Status.pending;

        this.accountApi.fetchAddresses()
            .then((response) => {
                // the response are always in standardized format - this includes "success" and "message" fields
                // they're not being utilized atm b/c the likelihood of problems is so low, but they exist, and
                // *can* be leveraged should the need arise
                const {content} = response;
                // the objects from the server should match the local model 1:1
                this.addresses = content.map((a: any) => new Address(a));

                this.shipping = this.addresses.filter(a => a.isShipping).find(a => a.isPrimary) || null;
                this.billing = this.addresses.filter(a => a.isBilling).find(a => a.isPrimary) || null;

                this.status.loadingAddresses = Status.complete;
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    fetchShippingMethods() {
        this.accountApi.fetchShippingMethods()
            .then(response => {
                // either everyone is in the pool, or just the ones marked valid for holidays
                this.shippingMethods = response.content.filter((sm: ShippingMethod) => normalShipping || sm.holiday);

                // an international method marked as default
                // @ts-ignore
                this.internationalShippingMethod = this.shippingMethods.find(sm => !sm.domestic && sm.defaultSelection);

                // a domestic shipping method that is marked as "default" with the holiday flag *opposite* normal shipping conditions
                // i.e. if normal shipping == true, holiday == false
                // @ts-ignore
                this.domesticShippingMethod = this.shippingMethods.find(sm => sm.domestic && sm.defaultSelection && normalShipping !== sm.holiday);
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    createAddress(address: Address) {
        return this.accountApi.createAddress(address)
            .then((response) => {
                const a = new Address(response.content);
                // add the newly created address to the local collection
                this.addresses.push(a);
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    updateAddress(address: Address) {
        return this.accountApi.updateAddress(address)
            .then((response) => {
                // convert into shim object
                const a = new Address(response.content);
                // remove the old record by ID
                const without = this.addresses.filter(i => i.id !== a.id);
                // replace with the new one
                this.addresses = [...without, a];
            }).catch((error) => {
                console.warn(error);
            });
    }

    deleteAddress(address: Address) {
        return this.accountApi.deleteAddress(address)
            .then(() => {
                // remove the old record by ID
                this.addresses = this.addresses.filter(i => i.id !== address.id);

                if (address.isPrimary) {
                    // old address was primary
                    if (address.isShipping) {
                        // AND shipping -> clear .shipping, select next to crown as primary
                        this.shipping = null;
                        // there could be no address left, hence the "?"
                        this.setShippingAddress(this.shippingAddress?.id);
                    }

                    if (address.isBilling) {
                        // AND billing -> clear .billing, select next to crown as primary
                        this.billing = null;
                        // there could be no address left, hence the "?"
                        this.setBillingAddress(this.billingAddress?.id);
                    }
                }
            }).catch((error) => {
                console.warn(error);
            });
    }

    setShippingAddress(id: string) {
        if (!id) {
            return;
        }

        // @ts-ignore
        this.updateAddress({id, isPrimary: true})
            .then(() => this.shipping = this.addresses.find(i => i.id === id) || null);
    }

    setBillingAddress(id: string) {
        if (!id) {
            return;
        }

        // @ts-ignore
        this.updateAddress({id, isPrimary: true})
            .then(() => this.billing = this.addresses.find(i => i.id === id) || null);
    }

    setAccountNumber(acct: string) {
        this.accountNumber = acct;
    }

    setShippingMethodInternational(method: ShippingMethod) {
        this.internationalShippingMethod = method;
    }

    setShippingMethodDomestic(method: ShippingMethod) {
        this.domesticShippingMethod = method;
    }

    get shippingMethod() {
        return this.domesticShippingCountry ? this.domesticShippingMethod : this.internationalShippingMethod;
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    get isLoading() {
        return this.status.loadingAddresses === Status.pending;
    }

    get shippingAddress() {
        if (this.shipping == null) {
            return this.shippingAddresses[0];
        } else {
            return this.shipping;
        }
    }

    get shippingAddresses() {
        return this.addresses.filter(i => i.isShipping);
    }

    get billingAddress() {
        if (this.billing === null) {
            return this.billingAddresses[0];
        } else {
            return this.billing;
        }
    }

    get billingAddresses() {
        return this.addresses.filter(i => i.isBilling);
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    get domesticShippingCountry() {
        return this.shippingCountry.toUpperCase() === UNITED_STATES && !OVERSEAS_TERRITORIES.includes(this.shippingState.toUpperCase());
    }

    get shippingCountry() {
        // ideally we'll use whatever is defined as the Country for the current shipping method, falling back as required
        return this.shippingAddress?.country || this.geolocationStore.effectiveCountryRegionId || UNITED_STATES;
    }

    get shippingState(): string {
        // ideally we'll use whatever is defined as the State, falling back to MI if required...
        // fun fact: "Michigan" is *not* a state in Germany
        return this.shippingAddress?.state || 'MI';
    }

    get flatRateShippingCountry() {
        return FLAT_RATE_SHIPPING_COUNTRIES.includes(this.shippingCountry);
    }

    toggleAddressSelectionDialog = () => {
        this.addressSelectionDialogOpen = !this.addressSelectionDialogOpen;
    };
}