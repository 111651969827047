import { CSSProperties, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { gtag } from '../../constants/gtag';
import { SubscribeLink } from './SubscribeLink';

interface InternalLinkProps {
    className?: string;
    style?: CSSProperties;
    title?: string;
    to: string;
    onClick?: () => void;
    event?: [string, object];

    children?: ReactNode
}

export default function InternalLink(props: InternalLinkProps) {
    const {children, event, className, style, to} = props;

    //  Intercept requests for '/subscribe', return custom-crafted external link for this Mailchimp form
    if (to === '/subscribe') {
        return <SubscribeLink className={className} style={style}>{children}</SubscribeLink>;
    }

    return (
        <NavLink
            className={`${props.className || ''}`}
            style={props.style}
            title={props.title}
            to={props.to}
            onClick={() => {
                if (event) {
                    gtag('event', ...event);
                }

                if (props.onClick) {
                    props.onClick();
                }
            }}>

            {props.children}
        </NavLink>
    );
}
