import { useEffect, useState } from 'react';
import HTML from '../../common/HTML';

const empty = '';

export function MOTD() {
    const [motd, setMotd] = useState<string>(empty);

    useEffect(() => {
        try {
            fetch(new URL(process.env.REACT_APP_MOTD as string))
                .then(response => response.ok ? response.text() : Promise.resolve(empty))
                .then(html => setMotd(html));
        } catch (err) {
            // pass
        }
    }, []);

    if (motd) {
        return <HTML markup={motd}/>;
    }

    return <></>;
}