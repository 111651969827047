import { HttpMethod } from '../../types/enum/HttpMethod';
import { msalStore } from '../../storeSingleton/msalStore';
import { ContentType } from '../ContentType';
import { seawolfUrl } from './url';

export const get = function (uri: string, params?: Object) {
    // complete the URL
    const url = seawolfUrl(uri, params);

    return msalStore.getToken().then((token) => {
        const method = HttpMethod.GET;
        const headers = {};

        if (token) {
            // @ts-ignore
            headers.Authorization = `Bearer ${token}`;
        }

        return fetch(url, {method, headers})
            .then(response => {
                // "Fetch promises only reject with a TypeError when a network error occurs.
                //  Since 4xx and 5xx responses aren't network errors, there's nothing to catch.
                //  You'll need to throw an error yourself to use Promise#catch."
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            });
    });
};

export const post = function (uri: string, payload: any = {}) {
    // complete the URL
    const url = seawolfUrl(uri);

    return msalStore.getToken().then((token) => {
        const method = HttpMethod.POST;
        const body = JSON.stringify(payload);
        const headers = {'Content-Type': ContentType.Json};

        if (token) {
            // @ts-ignore
            headers.Authorization = `Bearer ${token}`;
        }

        return fetch(url, {method, body, headers})
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            });
    });
};

// can't use "delete", that's a reserved word
export const obliterate = function (uri: string) {
    // complete the URL
    const url = seawolfUrl(uri);

    return msalStore.getToken().then((token) => {
        const method = HttpMethod.DELETE;
        const headers = {};

        if (token) {
            // @ts-ignore
            headers.Authorization = `Bearer ${token}`;
        }

        return fetch(url, {method, headers})
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            });
    });
};