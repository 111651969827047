import { get } from '../util/api/request';

export default class GeographyApi {
    async fetchCountries() {
        return get('/open/geography/countries');
    }

    async fetchStates() {
        return get('/open/geography/states');
    }

    async fetchTerritories() {
        return get('/open/geography/territories');
    }
}
