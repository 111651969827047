import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';
import { ResponsivePlayer } from './ResponsivePlayer';

const externalLinkPrefix = 'https://cdn.caymanchem.com/cdn/insert';

const internalLinkPrefix = 'https://www.caymanchem.com';
const internalLinkPrefixLength = internalLinkPrefix.length;

const isInternal = (href: string) => href.startsWith(internalLinkPrefix) && !href.startsWith(externalLinkPrefix);

const vimeo = new RegExp('player.vimeo.com/video/(?<id>\\d+)', 'i');

interface HTMLProps {
    markup: string;
}

export default function HTML(props: HTMLProps) {

    let {markup} = props;

    if (!markup) {
        return null;
    }

    // this kind of client-side HTML massaging is really quite ugly - it's messy and error prone
    // it's be far better if this were all Markdown, but that's not going to be an option :\

    // We have legacy links that target the deprecated static.caymanchem.com endpoint
    markup = markup.replaceAll('https://static.caymanchem.com/', 'https://cdn2.caymanchem.com/cdn/');
    // Point embedded CMS image URLs at CDN2 - notice the '="' to target media paths relative to the page host
    markup = markup.replaceAll('="/cms/caymanchem', '="https://cdn2.caymanchem.com/cdn/cms/caymanchem');

    return parse(markup, {
        replace: (node: any) => {
            //  short circuit since most of our nodes will be text
            const attribs = node.attribs;

            if (node.type === 'text' || typeof attribs === 'undefined' || attribs.name) {
                return false;
            }

            //  Internal links
            if (node.name === 'a' && attribs.href && !attribs.href.startsWith('#')) {
                const children = domToReact(node.children);

                const href = attribs.href;

                if (isInternal(href)) {
                    const to = href.substring(internalLinkPrefixLength);

                    return (
                        <InternalLink className={'text-underline'} to={to}>
                            {children}
                        </InternalLink>
                    );

                } else {
                    return (
                        <ExternalLink href={href}>
                            {children}
                        </ExternalLink>
                    );
                }
            }

            //  Images
            if (node.name === 'img') {
                return (
                    <LazyLoadImage
                        {...attributesToProps(node.attribs)}
                        src={attribs.src}/>
                );
            }

            //  Vimeo videos
            if (node.name === 'iframe' && vimeo.test(attribs.src)) {
                return (
                    <ResponsivePlayer
                        url={`https://vimeo.com/${vimeo.exec(attribs.src)?.groups?.id}`}/>
                );
            }

            return false;
        }
    });
}