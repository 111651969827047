import { action, computed, makeObservable, observable } from 'mobx';
import GeographyApi from '../api/GeographyApi';
import { CAYMAN_EUROPE_COUNTRIES, CAYMAN_EUROPE_FORENSIC_COUNTRIES } from '../constants/checkoutConstants';
import { CANADA, UNITED_STATES } from '../constants/geographies';
import Country from '../model/Country';
import State from '../model/State';
import Territory from '../model/Territory';
import { CheckoutProcessingCompany } from '../types/enum/CheckoutProcessingCompany';
import { Currency } from '../types/enum/Currency';
import { Pricing } from '../types/enum/Pricing';
import { Status } from '../types/enum/Status';
import RouterStore from './RouterStore';

export default class GeographyStore {
    countries: Country[] = [];
    states: State[] = [];
    territories: Territory[] = [];

    status = {
        countries: Status.initial,
        states: Status.initial,
        territories: Status.initial,
    };

    // package + hold these instead of continually .reduce()'ing on each get() call
    statesById: Map<string, string> = new Map();
    countriesById: Map<string, string> = new Map();
    territoriesById: Map<string, Territory> = new Map();

    geographyApi: GeographyApi;
    routerStore: RouterStore;

    constructor(geographyApi: GeographyApi, routerStore: RouterStore) {
        makeObservable(this, {
            countries: observable.ref,
            states: observable.ref,
            territories: observable.ref,
            status: observable,

            statesById: observable,
            countriesById: observable,
            territoriesById: observable,

            americanStates: computed,
            canadianProvinces: computed,

            // I'm not sure these *need* to be actions, but why not?
            isCeCountry: action,
            isCeForensicCountry: action,

            fetch: action,
            fetchCountries: action,
            fetchStates: action,
            fetchTerritories: action,
        });

        this.geographyApi = geographyApi;
        this.routerStore = routerStore;

        this.fetch();

        // this allows inspection of the mobx store w/o needing a breakpoint
        // @ts-ignore
        window.geographyStore = this;
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    fetch() {
        Promise.all([
            this.fetchCountries(),
            this.fetchStates(),
            this.fetchTerritories()
        ]).then(() => {
            // Congratulations, Canada - you've been promoted. Do not resist.
            this.statesById = [...this.americanStates, ...this.canadianProvinces].reduce((map, state) => map.set(state.stateId, state.name), new Map());

            this.countriesById = this.countries.reduce((map, country) => map.set(country.countryRegionId, country.name), new Map());
            this.territoriesById = this.territories.reduce((map, territory) => map.set(territory.territoryId, territory), new Map());
        });
    };

    fetchCountries() {
        if (this.status.countries === Status.complete) {
            return;
        }

        this.status.countries = Status.pending;

        return this.geographyApi.fetchCountries()
            .then((response: any) => {
                this.countries = response.content.slice();
                this.status.countries = Status.complete;
            });
    };

    fetchStates() {
        if (this.status.states === Status.complete) {
            return;
        }

        this.status.states = Status.pending;

        return this.geographyApi.fetchStates()
            .then((response: any) => {
                this.states = response.content.slice();
                this.status.states = Status.complete;
            });
    };

    fetchTerritories() {
        if (this.status.territories === Status.complete) {
            return;
        }

        this.status.territories = Status.pending;

        return this.geographyApi.fetchTerritories()
            .then((response: any) => {
                // @ts-ignore
                this.territories = response.content.slice().sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch(console.warn)
            .finally(() => this.status.territories = Status.complete);
    };

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    get americanStates() {
        return this.states
            .filter((state: State) => state.countryRegionId === UNITED_STATES)
            .sort((a, b) => a.stateId.localeCompare(b.stateId));
    }

    get canadianProvinces() {
        return this.states
            .filter((state: State) => state.countryRegionId === CANADA)
            .sort((a, b) => a.stateId.localeCompare(b.stateId));
    }

    //  Global Buyer's Guide territories broken into groups, CE === Cayman Europe
    isCeCountry(code: string) {
        return CAYMAN_EUROPE_COUNTRIES.some((territoryId: string) => territoryId === code);
    }

    //  Global Buyer's Guide territories broken into groups, CE === Cayman Europe
    isCeForensicCountry(code: string) {
        return CAYMAN_EUROPE_FORENSIC_COUNTRIES.some((territoryId: string) => territoryId === code);
    }

    territoryPricing(territory: Territory) {
        if (territory.usPricingFlag) {
            return Pricing.DOLLAR;
        }

        if (this.isCeCountry(territory.territoryId)) {
            return Pricing.EURO;
        }

        return this.routerStore.checkout ? Pricing.DOLLAR : Pricing.NONE;
    };

    territoryForensicPricing(territory: Territory) {
        if (territory.usPricingFlag) {
            return Pricing.DOLLAR;
        }

        if (this.isCeForensicCountry(territory.territoryId)) {
            return Pricing.EURO;
        }

        return this.routerStore.checkout ? Pricing.DOLLAR : Pricing.NONE;
    };

    territoryCurrency(territory: Territory) {
        if (territory.usPricingFlag) {
            return Currency.DOLLAR;
        }

        if (this.isCeCountry(territory.territoryId)) {
            return Currency.EURO;
        }

        return Currency.NONE;
    };

    territoryProcessor(code: string) {
        return this.isCeCountry(code) ?
            CheckoutProcessingCompany.CAYMAN_EUROPE :
            CheckoutProcessingCompany.CAYMAN_CHEMICAL;
    };
}
