import { RaptaId } from 'src/types/RaptaId';
import { CatalogNumber } from '../../types/CatalogNumber';

const cdn: boolean = true;

export const cdnUrl  = (uri: string, seawolf: boolean = true) => `https://cdn.caymanchem.com/cdn${seawolf ? '/seawolf' : ''}/${uri}`;
export const cdn2Url  = (uri: string) => `https://cdn2.caymanchem.com/cdn/${uri}`;

export const publicUrl = (uri: string) => `https://www.caymanchem.com${uri}`;
export const staticUrl = (uri: string) => `https://static.caymanchem.com/${uri}`;
export const certificateOfAnalysisUrl = (file: string) => cdnUrl(`downloadCofa/${file}`);
export const plateLayoutUrl = (file: string) => cdnUrl(`platelayout/${file}`);
export const cmsUrl = (uri: string) => cdn2Url(`cms/caymanchem/${uri}`);
export const cmsImageUrl = (uri: string) => cmsUrl(`cmsImages/${uri}`);
export const literatureCmsUrl = (uri: string) => cmsUrl(`LiteratureCMS/${uri}`);
export const literatureThumbnailUrl = (uri: string) => cmsUrl(`LiteratureThumbnails/${uri}`);
export const msdsUrl = (catalogNumber: CatalogNumber) => cdnUrl(`msds/${catalogNumber}m.pdf`);
export const insertUrl = (fileName: string) => cdnUrl(`insert/${fileName}`);
export const msdsUrlFileName = (fileName: string) => cdnUrl(`msds/${fileName}`);
export const cofaUrl = (catalogBatchNumber: string) => cdnUrl(`downloadCofa/${catalogBatchNumber}-CofA.pdf`);
export const isocofaUrl = (fileName: string) => cdnUrl(`iso/${fileName}`);
/** 2020 landing page managed content */
export const landingUrl = (uri: string, landing: string | undefined = undefined) => cmsImageUrl(`landing/${typeof landing === 'string' ? `${landing}/` : ''}${uri}`);

export const seawolfUrl = (uri: string, params?: Object) => {

    let base = `${process.env.REACT_APP_SEAWOLF_URL}${uri}`;

    if (params) {

        const queryParams = new URLSearchParams();

        Object.keys(params).forEach((key) => {
            if (params[key]) {
                queryParams.append(key, params[key]);
            }
        });

        base = base + '?' + queryParams;
    }

    return base;
};

export const productImageUrl = (itemId: string = '', size: string = 'page' ) => {
    const sizeUri = size === 'page' ? '' : `/${size}`;
    const fileName = itemId.endsWith('.png') ? itemId : `${itemId}.png`;

    return cdn ?
        cdn2Url(`productImages${sizeUri}/${fileName}`) :
        publicUrl(`/images/catalog/${sizeUri}/${fileName}`)
    ;
};

export const cslUrl = (uri: string) => cdnUrl(`csl/${uri}`, false);

export const searchTo = (raptaIds: RaptaId[] = [], q: string = '', catalogNums: string[] = []) => {
    const rr = raptaIds.length ? `raptas=${raptaIds.join(',')}` : '';
    const cc = catalogNums.length ? `catalogNums=${catalogNums.join(',')}` : '';
    const qq = q.length ? `q=${q}` : '';

    const qs = [rr, cc, qq].filter(s => !!s).join('&');

    return `/search?${qs}`;
};