import { CSSProperties, ReactNode } from 'react';
import ExternalLink from './ExternalLink';

interface SubscribeLinkProps {
    className?: string;
    style?: CSSProperties;

    children?: ReactNode
}

/**
 * Link to MailChimp subscription form
 * (with <ExternalLink/> since outside our React frontend)
 */
export function SubscribeLink(props: SubscribeLinkProps) {
    return (
        <ExternalLink
            className={props.className || ''}
            href={'https://www.caymanchem.com/subscribe'}
            style={props.style}>

            {props.children || 'Subscribe'}
        </ExternalLink>
    );
}