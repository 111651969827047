import 'bootstrap/dist/css/bootstrap.min.css';

import { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import App from './App';

import { MsalProvider } from '@azure/msal-react';
import { msalStore } from './storeSingleton/msalStore';
import { nautilusStore } from './storeSingleton/nautilusStore';

// @ts-ignore
window.msal = msalStore;
// @ts-ignore
window.nautilusStore = nautilusStore;

const partial = (
    <StrictMode>
        <CookiesProvider>
            <MsalProvider instance={msalStore.instance}>
                <App/>
            </MsalProvider>
        </CookiesProvider>
    </StrictMode>
);

const anchor = document.getElementById('root');

render(partial, anchor);
