// noinspection JSIgnoredPromiseFromCall

import { action, makeObservable, observable, } from 'mobx';
import UserApi from '../api/UserApi';
import User from '../model/User';
import { Status } from '../types/enum/Status';
import { RaptaId } from '../types/RaptaId';
import { msalStore } from '../storeSingleton/msalStore';
import Account from '../model/Account';

export default class UserStore {
    user: User | null;
    account: Account | null;

    userApi: UserApi;

    status = {
        areasOfInterestStatus: Status.initial,

        emailPrefStatus: Status.initial,
        productInsertStatus: Status.initial,
        personalInfoStatus: Status.initial,
    };

    areasOfInterest: Set<RaptaId> = new Set();

    constructor(userApi: UserApi) {
        makeObservable(this, {
            status: observable,
            user: observable,
            account: observable,
            areasOfInterest: observable,

            saveUser: action,
            saveEmailPrefs: action,
            saveInsertPrefs: action
        });

        this.userApi = userApi;

        this.user = null;
        this.account = null;

        msalStore.addReactionToStatusChange(
            () => {
                this.loadAreasOfInterest();
            },
            () => {
                this.user = null;
                this.account = null;
                this.areasOfInterest = new Set();
            }
        );

        // this allows inspection of the mobx store w/o needing a breakpoint
        // @ts-ignore
        window.userStore = this;
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    loadAreasOfInterest() {
        this.userApi.loadAreasOfInterest()
            .then((response) => {
                const {content} = response;
                content.forEach((aoi: any) => this.areasOfInterest.add(aoi.id));
            })
            .catch((error: any) => {
                console.warn(error);
            });
    }

    saveAreasOfInterest() {
        return this.userApi.saveAreasOfInterest([...this.areasOfInterest])
            .then((response) => {
                // clear
                this.areasOfInterest = new Set();
                // repopulate
                response.content.forEach((aoi: any) => this.areasOfInterest.add(aoi.id));
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    // **************************************************************************************************** //
    // **************************************************************************************************** //

    get flipbookAccess() {
        return !!(this.account?.flipbookAccess);
    }

    saveUser(user: any) {
        return this.userApi.saveUser(user)
            .then((response) => {
                this.user = {...response.content};
            })
            .catch((error: any) => {
                console.warn(error);
            });
    }

    saveEmailPrefs(doNotEmail: boolean) {
        this.status.emailPrefStatus = Status.pending;

        this.saveUser({doNotEmail})
            .then(() => this.status.emailPrefStatus = Status.complete)
            .catch(() => this.status.emailPrefStatus = Status.error)
            .finally(() => setTimeout(() => this.status.emailPrefStatus = Status.initial, 7000));
    }

    saveInsertPrefs(includeProductInserts: boolean) {
        this.status.productInsertStatus = Status.pending;

        this.saveUser({includeProductInserts})
            .then(() => this.status.productInsertStatus = Status.complete)
            .catch(() => this.status.productInsertStatus = Status.error)
            .finally(() => setTimeout(() => this.status.productInsertStatus = Status.initial, 7000));
    }
}