import { get, obliterate, post } from '../util/api/request';

import Address from '../model/Address';

export default class AccountApi {
    async fetchAddresses() {
        return get('/secured/addresses');
    }

    async fetchShippingMethods() {
        return get('/open/shipping/methods');
    }

    async createAddress(address: Address) {
        return AccountApi.issueUpdate('/secured/address', address);
    }

    async updateAddress(address: Address) {
        return AccountApi.issueUpdate(`/secured/address/${address.id}`, address);
    }

    async deleteAddress(address: Address) {
        return obliterate(`/secured/address/${address.id}`);
    }

    async deleteAccount() {
        return obliterate('/secured/user');
    }

    private static async issueUpdate(url: string, address: Address) {
        return post(url, address);
    }
}
