import { CSSProperties, ReactNode } from 'react';
import { gtag } from '../../constants/gtag';

interface ExternalLinkProps {
    className?: string;
    href: string;
    onClick?: () => void;
    style?: CSSProperties;
    title?: string;
    event?: [string, object];

    children?: ReactNode
}

export default function ExternalLink(props: ExternalLinkProps) {
    const event = props.event;

    return (
        <a
            className={`text-underline ${props.className || ''}`}
            href={props.href}
            title={props.title}
            onClick={() => {
                if (event) {
                    gtag('event', ...event);
                }

                if (props.onClick) {
                    props.onClick();
                }
            }}
            rel={'noreferrer'}
            style={props.style}
            target={'_blank'}>

            {props.children}
        </a>
    );
}