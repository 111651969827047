import ReactPlayer from 'react-player';
import { CSSProperties } from 'react';

//  https://www.npmjs.com/package/react-player

export function ResponsivePlayer(props: { url: string }) {
    const o_s: CSSProperties = {
        position: 'relative',
        paddingTop: '56.25%',
    };

    const i_s: CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
    };

    return (
        <div style={o_s}>
            <ReactPlayer
                controls={true}
                url={props.url}
                width='100%'
                height='100%'
                style={i_s}
            />
        </div>
    );
}