import * as MobXReactRouter from '@superwf/mobx-react-router';
import { computed, makeObservable } from 'mobx';

const fullSearchPattern = new RegExp('^/search?', 'i'); //  Don't .compile() RegExp, happens automatically now
const checkoutSearchPattern = new RegExp('^/checkout?', 'i');


export default class RouterStore extends MobXReactRouter.RouterStore {

    constructor() {
        super();

        makeObservable(this, {
            fullSearchMode: computed,
            checkout: computed,
            currentPath: computed,
            currentPathComponents: computed,
            lastPathComponent: computed,

            tab: computed
        });

        // this allows inspection of the mobx store w/o needing a breakpoint
        // @ts-ignore
        window.routerStore = this;
    }

    get fullSearchMode(): boolean {
        return fullSearchPattern.test(this.currentPath);
    }

    get checkout(): boolean {
        return checkoutSearchPattern.test(this.currentPath);
    }

    get currentPath(): string {
        document.title = 'Cayman Chemical'; //  reset document title on every navigation, expecting new page to customize
        return this.location ? this.location.pathname : (window as Window).location.pathname;
    }

    get currentPathComponents(): string[] {
        return this.currentPath.split('/');
    }

    get lastPathComponent(): string {
        return this.currentPathComponents.slice(-1)[0];
    }

    get tab(): string {
        return this.currentPathComponents[2];
    }
}