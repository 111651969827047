import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';

export interface ShippingMethod {
    key: string;
    label: string;
    collect: boolean;
    domestic: boolean;
    price: number;
    holiday: boolean;

    defaultSelected: boolean;
}

export const normalShipping: boolean = isFuture(parseISO('2022-12-15 00:00:00')) || isPast(parseISO('2022-12-23 23:59:59'));

export const CAYMAN_GEAR_FREE_DOMESTIC_SHIPPING = 'USPS';

