/**
 * Types for fetch status
 */
export enum Status {
    /** No fetch attempt has taken place yet */
    initial = 'initial',

    /** Fetch in progress */
    pending = 'pending',

    /** Fetch completed successfully */
    complete = 'complete',

    /** Fetch failed */
    error = 'error',
}