import * as Yup from 'yup';
import { ValidationMessage } from '../constants/forms/messages/validation';
import { UNITED_STATES } from '../constants/geographies';

export default class Address {
    id = '';
    name = '';
    street = '';
    city = '';
    state = '';
    postalCode = '';
    country = '';

    isBilling = false;
    isShipping = false;

    isPrimary = false;

    constructor(raw: any = {}) {
        let json;

        try {
            json = JSON.parse(raw);
        } catch (e) {
            json = raw;
        }

        this.id = json.id || '';
        this.name = json.name || '';
        this.street = json.street || '';
        this.city = json.city || '';
        this.state = json.state || '';
        this.postalCode = json.postalCode || '';
        this.country = json.country || '';

        this.isBilling = json.isBilling;
        this.isShipping = json.isShipping;

        this.isPrimary = json.isPrimary;
    }

    static initial(address?: any) {
        const add = address ? address : {};

        return {
            id: add?.id || '',
            name: add?.name || '',
            street: add?.street || '',
            city: add?.city || '',
            state: add?.state || '',
            postalCode: add?.postalCode || '',
            country: add?.country || '',
        };
    }

    static validation() {
        return Yup.object().shape({
            name: Yup.string().required(ValidationMessage.name),
            street: Yup.string().required(ValidationMessage.address),
            city: Yup.string().required(ValidationMessage.city),
            state: Yup.string().when('country', {
                is: (country) => country && country.toUpperCase() === UNITED_STATES,
                then: Yup.string().required(ValidationMessage.state),
                otherwise: Yup.string().notRequired()
            }),
            postalCode: Yup.string().required(ValidationMessage.postalCode),
            country: Yup.string().required(ValidationMessage.country)
        });
    }
}

const addressSchema = {
    address1: Yup.string().required(ValidationMessage.required),
    address2: Yup.string(),
    city: Yup.string().required(ValidationMessage.required),
    postalCode: Yup.string().required(ValidationMessage.required),
};

export const cdcRequiredAddressSchema = Yup.object().shape({
    ...addressSchema,
    state: Yup.string().required(ValidationMessage.required),
});

export const cdcRequiredInternationalSchema = Yup.object().shape({
    ...addressSchema,
    country: Yup.string().required(ValidationMessage.required),
});