// integration guide
// https://learn.microsoft.com/en-us/azure/active-directory-b2c/configure-authentication-sample-react-spa-app

// GitHub link from ^ which all this was yanked from:
// https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/3-Authorization-II/2-call-api-b2c/SPA/src/authConfig.js

/*
In AADB2C there are "User Flows" and "App Registrations", and this is my basic understanding of how they function:

User Flows control the behavior of the popup displayed on the page; their basic actions and UI are preconfigured by Azure,
but you can specificy which fields are captured for, say, the "B2C_1_seawolf_sign_in" flow, along with token liftimes and session behavior;
under "Properties" the "Single sign-on configuration" should (probably) always be "Tenant"

App Registrations are the applications permitted to chatter with AADB2C:

"Nautilus"
    - frontend application; it's what MSAL is connecting with and (I believe) what's *actually* controlling User Authentication
    - under "Authentication", it defines several redirect URI's, which must match the domain the application lives in i.e. "tin.caymanchem.com"
    - under "API permissions" there is "seawolf.read" and "seawolf.write" - I *think* this grants "seawolf" permission to talk *to* "nautilus",
      so that the backend can verify JWT tokens (but I could be wrong about the flow - it's internal to Azure, so idk how it exactly sloshes about)
    - 23b2a5bf-fa83-4035-9be2-e160a401504b

"Seawolf"
    - backend application; the actual SPA server
    - this is configured (server side) as a OAuth Resource Server (iirc), so there's nothing under "Authentication"
    - there are two exposed API's: one for write access, one for read access; these show up both as permissions to above, *and* as "scopes" down below
    - under its permissions, you'll see a lot more "Microsoft Graph" permissions - this is for accessing, server side, the AAD records for users
    - 4ba85f79-bdb9-4f22-8560-e027f2699391
 */

import { LogLevel } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_seawolf_sign_in_or_sign_up',
        forgotPassword: 'B2C_1_seawolf_password_reset',
        editProfile: 'B2C_1_seawolf_profile_edit'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://caymanchemicalpublic.b2clogin.com/caymanchemicalpublic.onmicrosoft.com/B2C_1_seawolf_sign_in_or_sign_up',
        },
        forgotPassword: {
            authority: 'https://caymanchemicalpublic.b2clogin.com/caymanchemicalpublic.onmicrosoft.com/B2C_1_seawolf_password_reset',
        },
        editProfile: {
            authority: 'https://caymanchemicalpublic.b2clogin.com/caymanchemicalpublic.onmicrosoft.com/B2C_1_seawolf_profile_edit',
        },
        signUp: {
            authority: 'https://caymanchemicalpublic.b2clogin.com/caymanchemicalpublic.onmicrosoft.com/B2C_1_seawolf_sign_up'
        },
        signIn: {
            authority: 'https://caymanchemicalpublic.b2clogin.com/caymanchemicalpublic.onmicrosoft.com/B2C_1_seawolf_sign_in'
        }
    },
    authorityDomain: 'caymanchemicalpublic.b2clogin.com',
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: '23b2a5bf-fa83-4035-9be2-e160a401504b', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUp.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        //redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        //postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            // @ts-ignore
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

const scopes = [
    'https://caymanchemicalpublic.onmicrosoft.com/4ba85f79-bdb9-4f22-8560-e027f2699391/seawolf.write',
    'https://caymanchemicalpublic.onmicrosoft.com/4ba85f79-bdb9-4f22-8560-e027f2699391/seawolf.read'
];

export const loginRequest = {
    scopes,
    authority: b2cPolicies.authorities.signIn.authority
};

export const signUpRequest = {
    scopes,
    authority: b2cPolicies.authorities.signUp.authority
};

export const loginOrSignUpRequest = {
    scopes,
    authority: b2cPolicies.authorities.signUpSignIn.authority
};

export const resetPassword = {
    scopes,
    authority: b2cPolicies.authorities.forgotPassword.authority
};