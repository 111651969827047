import { get, post } from '../util/api/request';

export default class UserApi {

    async loadAreasOfInterest() {
        return get('/secured/account/areasofinterest/');
    }

    async saveAreasOfInterest(raptas: Array<string>) {
        return post('/secured/account/areasofinterest/', {raptas});
    }

    async saveUser(values: any) {
        return post('/secured/user', values);
    }
}
