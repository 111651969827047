export const CAYMAN_EUROPE_COUNTRIES = [
    'AD',
    'AE',
    'AL',
    'AM',
    'AZ',
    'BA',
    'BD',
    'BG',
    'BH',
    'BY',
    'CY',
    'CZ',
    'DJ',
    'DK',
    'DZ',
    'EE',
    'EG',
    'ES',
    'FI',
    'GE',
    'GI',
    'HR',
    'HU',
    'IE',
    'IQ',
    'IS',
    'IT',
    'KG',
    'KM',
    'KW',
    'KZ',
    'LB',
    'LI',
    'LT',
    'LV',
    'MA',
    'MC',
    'MD',
    'ME',
    'MK',
    'MN',
    'MR',
    'MT',
    'NO',
    'OM',
    'PK',
    'PT',
    'QA',
    'RO',
    'RS',
    'SA',
    'SE',
    'SI',
    'SK',
    'SM',
    'SO',
    'TD',
    'TJ',
    'TM',
    'TN',
    'UA',
    'UZ',
    'XK',
].sort();
export const CAYMAN_EUROPE_FORENSIC_COUNTRIES = ['AL', 'DZ', 'AM', 'BD', 'BY', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EG', 'EE', 'FI', 'FR', 'GE', 'DE', 'GI', 'GR', 'GL', 'HU', 'IS', 'IE', 'IT', 'KZ', 'XK', 'LV', 'LT', 'MK', 'MT', 'MR', 'ME', 'MA', 'NO', 'PK', 'PT', 'RO', 'RS', 'SA', 'SK', 'SI', 'ES', 'SE', 'CH', 'TJ', 'TN', 'TM', 'UA', 'UZ'].sort();
export const FLAT_RATE_SHIPPING_COUNTRIES = ['CA', 'PR', 'US'].sort();