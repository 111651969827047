import { makeObservable, observable } from 'mobx';
import { get } from '../util/api/request';
import { msalStore } from './msalStore';
import { userStore } from './userStore';
import { addressStore } from './addressStore';

class NautilusStore {

    loadingBasicUserInfo: boolean = false;

    constructor() {
        makeObservable(this, {
            loadingBasicUserInfo: observable,
        });

        msalStore.addReactionToStatusChange(
            () => this.loadUserInformation(),
            () => {
            }
        );

        this.loadUserInformation();
    }

    loadUserInformation() {
        // no sense doubling up requests
        if (this.loadingBasicUserInfo) {
            return;
        }

        // no sense attempting to get information about user when they're not even logged in...
        if (msalStore.isLoggedOut) {
            return;
        }

        this.loadingBasicUserInfo = true;

        // design philosophy moment: I strongly dislike crossing wires unnecessarily
        // the "/secured/user" endpoint is doing some cross-checking of D365 against AAD to ensure recently registered
        // users are properly populated in the CRM; this includes both Contact _and_ Address records, which are returned to the client together, in a single object
        // I _could_ just slam a "addressStore.address = ..." into the userStore, but that would introduce a fixed dependency between the two where one did not previously exist...

        // I believe it is far cleaner and more flexible to have a third store sitting "above" both the userStore and the addressStore
        // it is in here that basic user info is fetched, and the resultant data portioned out accordingly
        //  - no direct linkages between unrelated object stores
        //  - can expand data fetch / handling without worrying about cross-talk
        //  - centralizes fetch, easing debugging

        const promises = [
            get('/secured/user')
                .then(({content}) => {
                    userStore.user = content.contact;
                    addressStore.addresses = content.addresses;
                }),
            get('/secured/account/')
                .then(({content}) => {
                    userStore.account = content;
                })
        ];

        Promise.all(promises)
            .catch((error: any) => {
                console.warn(error);
            })
            .finally(() => {
                this.loadingBasicUserInfo = false;
            });
    }

    get user() {
        // spread to disconnect from "live" object
        return {...userStore.user};
    }

    get addresses() {
        return [...addressStore.addresses];
    }
}

export const nautilusStore = new NautilusStore();