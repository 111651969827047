import { action, computed, makeObservable, observable } from 'mobx';
import Cookie from 'mobx-cookie';
import { CAYMAN_EUROPE_COUNTRIES, CAYMAN_EUROPE_FORENSIC_COUNTRIES } from '../constants/checkoutConstants';
import { UNITED_STATES } from '../constants/geographies';
import Territory from '../model/Territory';
import { Pricing } from '../types/enum/Pricing';
import { Status } from '../types/enum/Status';
import { seawolfUrl } from '../util/api/url';
import GeographyStore from './GeographyStore';

const geoipUrl = seawolfUrl('/open/geoip');

/**
 * This store holds information about the user's location.
 * For general geography database (countries and states/provinces)
 * @see GeogaraphyStore.
 */
export default class GeolocationStore {
    geographyStore: GeographyStore;
    userSelectionCookie: any = new Cookie('countryRegionId', {expires: 7, sameSite: 'Strict'});

    geolocatedCountryRegionId: string = UNITED_STATES;

    geolocatedCookie: any = new Cookie('geolocatedCountryRegionId', {expires: 7, sameSite: 'Strict'});

    status: Status = Status.initial;

    constructor(geographyStore: GeographyStore) {
        makeObservable(this, {
            userSelectionCookie: observable,
            geolocatedCountryRegionId: observable,
            geolocatedCookie: observable,
            status: observable,
            effectiveCountryRegionId: computed,
            effectiveTerritory: computed,
            inCeCountry: computed,
            pricing: computed,
            pricingAvailable: computed,
            fetch: action,
            setCountryRegionIdCookie: action
        });

        this.geographyStore = geographyStore;
        this.fetch();
    }

    get effectiveCountryRegionId(): string {
        //  1. User Selection Cookie
        const userSelectionCookieValue = this.userSelectionCookie.value;

        if (userSelectionCookieValue) {
            localStorage.setItem('userSelectedCountry', userSelectionCookieValue);
            return userSelectionCookieValue;
        }

        //  2,  Geolocated cookie

        const geolocatedCookieValue = this.geolocatedCookie.value;

        if (geolocatedCookieValue) {
            return geolocatedCookieValue;
        }

        // TS: this was causing an error regarding changing state from a computed, i don't think it will ever need to fetch here, as we have already called fetch by the time we get to this point (see AppInitializer)
        //  3. Network
        // if (this.status !== Status.complete) {
        //     this.fetch();
        // }

        return this.geolocatedCountryRegionId;
    }

    get effectiveTerritory(): Territory | undefined {
        return this.geographyStore.territoriesById.get(this.effectiveCountryRegionId);
    }

    get inCeCountry(): boolean {
        return this.geographyStore.isCeCountry(this.effectiveCountryRegionId) || CAYMAN_EUROPE_COUNTRIES.includes(localStorage.getItem('userSelectedCountry') || '');
    }

    /**
     * pricing for this region for forensic products
     *
     * See pricing() for logic
     *
     * TODO combine with pricing() method (only difference is Cayman Europe country list) (if even possible to combine computed)
     */
    get forensicPricing(): Pricing {
        if (this.geographyStore.isCeForensicCountry(this.effectiveCountryRegionId)) {
            return Pricing.EURO;
        }

        if (typeof this.effectiveTerritory === 'undefined') {
            return Pricing.DOLLAR;
        }

        return this.effectiveTerritory.usPricingFlag ? Pricing.DOLLAR : Pricing.NONE;
    }

    /**
     * pricing for this region ignoring whether product is forensic or not)
     *
     */
    get pricing(): Pricing {
        // in a country Cayman Europe services? then use Euros
        if (this.geographyStore.isCeCountry(this.effectiveCountryRegionId)) {
            return Pricing.EURO;
        }

        // don't know where we are? use dollars
        if (typeof this.effectiveTerritory === 'undefined') {
            return Pricing.DOLLAR;
        }

        //  we know where are, check the Global Buyer's Guide for us pricing flag
        //  (managed by marketing staff in SharePoint CMS)
        return this.effectiveTerritory.usPricingFlag ? Pricing.DOLLAR : Pricing.NONE;
    }

    get inCeForensicCountry(): boolean {
        return this.geographyStore.isCeForensicCountry(this.effectiveCountryRegionId) || CAYMAN_EUROPE_FORENSIC_COUNTRIES.includes(localStorage.getItem('userSelectedCountry') || '');
    }

    get pricingAvailable(): boolean {
        return this.pricing !== Pricing.NONE;
    }

    /*
            1.  Permanent userSelectionCookie stored by user selection
            2.  Short-lived userSelectionCookie storing geolocation result
            3.  Network geolocation
            4.  Default to US

        NB: We never used HTML5 location.

        TODO this should be broken into two methods: (1) "init" to be called where "fetch" is now; (2) "fetch" that does *just* the fetch
     */
    fetch() {
        //  short-circuit if either cookie is present
        if (this.userSelectionCookie.value || this.geolocatedCookie.value) {
            return;
        }

        if (this.status === Status.initial || this.status === Status.error) {
            this.status = Status.pending;

            fetch(geoipUrl)
                .then(response => response.json())
                .then((response: any) => {

                    const geoData = response.content;

                    if (geoData.hasOwnProperty('country_code')) {
                        //  Service returned an answer
                        this.status = Status.complete;

                        //  Answer will be null from our development and staging environments
                        if (geoData.country_code !== null) {
                            //  Override default if location was determined

                            this.geolocatedCountryRegionId = geoData.country_code;

                            // https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-make-the-cookie-expire-in-less-than-a-day
                            this.geolocatedCookie.set(this.geolocatedCountryRegionId, {expires: 0.5, sameSite: 'Strict'});

                            //  (Okay to accept default if service failed to determined location, e.g., form our development and staging environment)
                        }
                    } else {
                        this.status = Status.error;
                    }
                })
                .catch(err => {
                    console.warn(err);
                    this.status = Status.error;
                });
        }
    }

    setCountryRegionIdCookie(countryRegionId: string) {
        this.userSelectionCookie.set(countryRegionId, {expires: 7, sameSite: 'Strict'});
    }
}