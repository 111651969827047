export enum ValidationMessage {
    address = 'You must enter a street address, the address cannot start with \"Attn\", please use the Delivery/Attention name field for this information',
    atLeastOne = 'Please select at least one',
    city = 'You must enter a city',
    consent = 'This must be checked to proceed.',
    country = 'You must enter a country',
    email = 'Please enter a valid email address.',
    emailsMatch = 'Email must match.',
    file = 'Your file is too big, please select a file under 5MB',
    firstName = 'You must enter a first name',
    future = 'Must be a date in the future',
    institutionSelfReported = 'You must enter a research institution or company name',
    lastName = 'You must enter a last name',
    name = 'You must enter a name',
    number = 'You must enter a number',
    passwordsMatch = 'Password must match.',
    positiveNumber = 'Please enter a positive number',
    postalCode = 'You must enter a zip or postal code, if you are located in the US you must use a 5 digit postal code',
    province = 'You must enter a province',
    quantity = 'Please enter a number',
    recaptcha = 'You must pass the reCAPTCHA',
    receive = 'Please select one of the options above to proceed.',
    required = 'This field is required.',
    state = 'You must enter a state',
    telephone = 'You must enter a phone number',
    terms = 'This must be checked to proceed.',
    trim = 'No trailing spaces or tabs',
}