import './style/scss/app.scss';

import loadable from '@loadable/component';
import { configure } from 'mobx';
import preval from 'preval.macro';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { MOTD } from './component/cch/common/MOTD';
import ScrollToTop from './component/ScrollToTop';
import { BASENAME, WindowWithTinker } from './constants';

const About = loadable(() => import('./component/cch/page/About'));
const AccountView = loadable(() => import('./component/cch/page/account/AccountView'));
const AnalysisToolsView = loadable(() => import('./component/cch/page/analysisTools/AnalysisToolsView'));
const ApisView = loadable(() => import('./component/cch/page/apis/ApisView'));
const BatchView = loadable(() => import('./component/cch/page/BatchView'));
const BlankView = loadable(() => import('./component/cch/page/BlankView'));
const BrowserView = loadable(() => import('./component/cch/page/BrowserView'));
const CalendarView = loadable(() => import('./component/cch/page/CalendarView'));
const CancerView = loadable(() => import('./component/cch/page/cancer/CancerView'));
const CannabinoidResearchToolsView = loadable(() => import('./component/cch/landing/pages/CannabinoidResearchToolsView'));
const CannabisAndHempAnalyticalStandardsView = loadable(() => import('./component/cch/landing/pages/CannabisAndHempAnalyticalStandardsView'));
const CategoriesView = loadable(() => import('./component/cch/page/CategoriesView'));
const CdcFasKitDocuments = loadable(() => import('./component/cch/forensics/CdcFasKitDocuments'));
const CheckoutView = loadable(() => import('./component/cch/page/CheckoutView'));
const ChemAssistantView = loadable(() => import('./component/cch/page/ChemAssistantView'));
const ConferenceScheduleView = loadable(() => import('./component/cch/page/ConferenceScheduleView'));
const ContactView = loadable(() => import('./component/cch/page/contact/ContactView'));
const CoronavirusResearchToolsView = loadable(() => import('./component/cch/landing/pages/CoronavirusResearchToolsView'));
const CopyrightView = loadable(() => import('./component/cch/page/CopyrightView'));
const EquipmentView = loadable(() => import('./component/cch/page/EquipmentView'));
const FAQItem = loadable(() => import('./component/cch/page/FAQItem'));
const FAQView = loadable(() => import('./component/cch/page/FAQView'));
const FlipbookView = loadable(() => import('./component/cch/page/forensics/FlipbookView'));
const ForensicsView = loadable(() => import('./component/cch/page/forensics/ForensicLanding'));
const GlobalBuyersGuideView = loadable(() => import('./component/cch/page/GlobalBuyersGuideView'));
const HMRPView = loadable(() => import('./component/cch/page/HMRPView'));
const HRView = loadable(() => import('./component/cch/page/HRView'));
const IntellectualProperty = loadable(() => import('./component/cch/page/IntellectualProperty'));
const KinasesView = loadable(() => import('./component/cch/landing/pages/KinasesView'));
const LipidNanoparticlesView = loadable(() => import('./component/cch/landing/pages/LipidNanoparticlesView'));
const LiteratureItemView = loadable(() => import('./component/cch/page/LiteratureItemView'));
const LiteratureView = loadable(() => import('./component/cch/page/LiteratureView')) as any;
const LipidsView = loadable(() => import('./component/cch/page/lipids/LipidsView'));
const MatreyaMoveFAQ = loadable(() => import('./component/cch/page/MatreyaMoveFAQ'));
const MolarityCalculatorView = loadable(() => import ('./component/cch/page/chemAsisstant/MolarityCalculatorView'));
const MolarMassAndMassPercentCompositionCalculator = loadable(() => import('./component/cch/page/chemAsisstant/MolarMassAndMassPercentCompositionCalculator'));
const NewProductSuggestion = loadable(() => import('./component/cch/page/NewProductSuggestion'));
const NewsItemView = loadable(() => import('./component/cch/page/NewsItemView'));
const NewsView = loadable(() => import('./component/cch/page/NewsView')) as any;
const OrderTermsView = loadable(() => import('./component/cch/page/OrderTermsView'));
const PrecellysView = loadable(() => import('./component/cch/page/PrecellysView'));
const PrivacyView = loadable(() => import('./component/cch/page/PrivacyView'));
const ProductView = loadable(() => import('./component/cch/page/ProductView'));
const ProductPartnersView = loadable(() => import('./component/cch/page/ProductPartnersView'));
const PsychedelicsView = loadable(() => import('./component/cch/landing/pages/PsychedelicsView'));
const RunTheCaymanComparisonView = loadable(() => import('./component/cch/landing/pages/RunTheCaymanComparisonView'));
const SearchFullView = loadable(() => import('./component/cch/page/SearchFullView'));
const ServicesView = loadable(() => import('./component/cch/page/services/ServicesView'));
const SitemapView = loadable(() => import('./component/cch/page/Sitemap'));
const Staff = loadable(() => import('./component/cch/page/Staff'));
const CaymanNavbar = loadable(() => import('./component/cch/navigation/CaymanNavbar'));
const StructureDefinitionsView = loadable(() => import('./component/cch/page/StructureDefinitionsView'));
const StyleView = loadable(() => import('./component/cch/page/StyleView'));
const SupplierView = loadable(() => import('./component/cch/page/SupplierView'));
const TestView = loadable(() => import('./component/cch/page/TestView'));
const FontView = loadable(() => import('./component/cch/page/FontView'));
const ComponentShowcase = loadable(() => import('./component/cch/page/ComponentShowcase'));
const Footer = loadable(() => import('./component/cch/navigation/Footer'));
const Home = loadable(() => import('./component/cch/page/home/Home'));
const Header = loadable(() => import('./component/cch/navigation/Header'));
const ErrorView = loadable(() => import('./component/cch/ErrorView'));
const TechnicalSupportView = loadable(() => import('./component/cch/support/TechnicalSupport'));
const CookieBanner = loadable(() => import('./component/cch/navigation/CookieBanner'));

configure({enforceActions: 'never'});

const fasKitUri = '/forensics/search/faskit';

declare let window: WindowWithTinker;
// once declared here ^ we can slap whatever we'd like into it
window.app = {
    printRouteChanges: process.env.DEBUG_PRINT_ROUTE_CHANGES || false,
};

// class DebugRouter extends React.PureComponent<RouterProps> {
//     constructor(props: RouterProps) {
//         super(props);
//         props.history.listen((location, action) => {
//             if (window.app.printRouteChanges) {
//                 console.log(action, location);
//             }
//         });
//     }
//
//     render() {
//         return <Router {...this.props}>{this.props.children}</Router>;
//     }
// }

function App() {

    // const browserHistory = createBrowserHistory({basename: BASENAME});
    // const history: SynchronizedHistory = syncHistoryWithStore(browserHistory, routerStore);

    const darkMode: string = 'darkMode';

    return (
        <div
            data-timestamp={preval`module.exports = new Date().toLocaleString();`}
            data-environment={process.env.REACT_APP_ENVIRONMENT}
            data-darkmode={darkMode}
            style={location.port === '4444' ? {
                borderTop: 'solid 10px',
                borderImage: 'repeating-linear-gradient( -75deg, yellow, yellow 10px, black 10px, black 20px) 20',
            } : {}}>

            <CookieBanner/>

            <MOTD/>

            <Container
                className={'h-100'}
                fluid={true}
                data-node-env={process.env.NODE_ENV}>

                <Helmet>
                    <title>Cayman Chemical</title>
                    <meta
                        name={'REACT_APP_COMPANY_NAME'}
                        content={process.env.REACT_APP_COMPANY_NAME}/>
                </Helmet>

                <BrowserRouter basename={BASENAME}>
                    <ScrollToTop/>

                    <div id={'site-body'} className={'bg-white'}>

                        <Header/>
                        <CaymanNavbar/>

                        <Row
                            id={'site-router'}
                            className={'bg-gray border-top border-gray'}>

                            <Routes>

                                <Route index element={<Home/>}/>
                                <Route path={'/home'} element={<Home/>}/>
                                <Route path={'/index'} element={<Home/>}/>

                                <Route
                                    path="/newproductsuggestion"
                                    element={<NewProductSuggestion/>}/>

                                <Route
                                    path="/search"
                                    element={<SearchFullView/>}/>

                                <Route
                                    path={'/article'}
                                    element={<Navigate to={'/news'} replace/>}/>

                                <Route
                                    path="/about"
                                    element={<About/>}/>

                                <Route
                                    path="/analysistools/*"
                                    element={<AnalysisToolsView/>}/>

                                <Route
                                    path="/batch"
                                    element={<BatchView/>}/>

                                <Route
                                    path="/blank"
                                    element={<BlankView/>}/>

                                <Route
                                    path="/browser"
                                    element={<BrowserView/>}/>

                                <Route
                                    path="/apis/*"
                                    element={<ApisView/>}/>

                                <Route
                                    path="/calendar"
                                    element={<CalendarView/>}/>

                                <Route
                                    path={'/cannabinoidresearchtools'}
                                    element={<CannabinoidResearchToolsView/>}/>

                                <Route
                                    path={'/cannabisandhempanalyticalstandards'}
                                    element={<CannabisAndHempAnalyticalStandardsView/>}/>

                                <Route
                                    path={'/caymanchallenge'}
                                    element={<Navigate to={'/home'} replace/>}/>

                                <Route
                                    path="/productQualifiers/Home"
                                    element={<CategoriesView/>}/>

                                <Route
                                    path="/products/categories"
                                    element={<CategoriesView/>}/>

                                <Route
                                    path={'/products/kits'}
                                    element={<Navigate to={'/search?raptas=RAP000008'} replace/>}/>

                                <Route
                                    path={'/products/proteins'}
                                    element={<Navigate to={'/search?raptas=RAP000073'} replace/>}/>

                                <Route
                                    path={'/chemassistant/tool/4002'}
                                    element={<Navigate to={'/chemassistant/molarmass'} replace/>}/>

                                <Route
                                    path={'/chemassistant/molarmass'}
                                    element={<MolarMassAndMassPercentCompositionCalculator/>}/>

                                <Route
                                    path={'/chemassistant/tool/4003'}
                                    element={<Navigate to={'/chemassistant/molaritycalculator'} replace/>}/>

                                <Route
                                    path={'/chemassistant/molaritycalculator'}
                                    element={<MolarityCalculatorView/>}/>

                                <Route
                                    path="/chemassistant"
                                    element={<ChemAssistantView/>}/>

                                <Route
                                    path="/checkout"
                                    element={<CheckoutView/>}/>

                                <Route
                                    path="/conferenceschedule"
                                    element={<ConferenceScheduleView/>}/>

                                <Route
                                    path={'/contact'}
                                    element={<Navigate to={'/contact/cc'} replace/>}/>

                                <Route
                                    path={'/maps'}
                                    element={<Navigate to={'/contact/cc'} replace/>}/>

                                <Route
                                    path="/contact/*"
                                    element={<ContactView/>}/>

                                <Route
                                    path={'/coronavirusresearchtools'}
                                    element={<CoronavirusResearchToolsView/>}/>

                                <Route
                                    path="/copyright"
                                    element={<CopyrightView/>}/>

                                <Route
                                    path="/equipment"
                                    element={<EquipmentView/>}/>

                                <Route
                                    path={'/equipmentsampleprep'}
                                    element={<Navigate to={'/equipment'} replace/>}/>

                                <Route
                                    path="/faq/:q"
                                    element={<FAQItem/>}/>

                                <Route
                                    path="/faq"
                                    element={<FAQView showFacets={true}/>}/>

                                <Route
                                    path="/faskitdocuments"
                                    element={<CdcFasKitDocuments/>}/>

                                <Route
                                    path={'/font'}
                                    element={<FontView/>}/>

                                <Route
                                    path="/forensics/*"
                                    element={<ForensicsView/>}/>

                                <Route
                                    path={'/globalbuyersguide'}
                                    element={<GlobalBuyersGuideView/>}/>

                                <Route
                                    path={'/globalbuyersguide/home'}
                                    element={<Navigate to={'/globalbuyersguide/us'} replace/>}/>

                                <Route
                                    path={'/globalbuyersguide/territory/:territoryId'}
                                    element={<Navigate to={'/globalbuyersguide/:territoryId'} replace/>}/>

                                <Route
                                    path="/globalbuyersguide/:territoryId"
                                    element={<GlobalBuyersGuideView/>}/>

                                <Route
                                    path="/hmrp/:promotion?"
                                    element={<HMRPView/>}/>

                                <Route
                                    path="/hr"
                                    element={<HRView/>}/>

                                <Route
                                    path="/intellectualproperty"
                                    element={<IntellectualProperty/>}/>

                                <Route
                                    path={'/kinases'}
                                    element={<KinasesView/>}/>

                                <Route
                                    path={'/kitRecommendations'}
                                    element={<Navigate to={'/products/categories'} replace/>}/>

                                <Route
                                    path={'/lipid-nanoparticles'}
                                    element={<LipidNanoparticlesView/>}
                                />

                                <Route
                                    path={'/lipids'}
                                    element={<Navigate to={'/lipids/overview'} replace/>}/>

                                <Route
                                    path="/lipids/*"
                                    element={<LipidsView/>}/>

                                <Route
                                    path="/literature/:urlName"
                                    element={<LiteratureItemView/>}/>

                                <Route
                                    path="/literature"
                                    element={<LiteratureView/>}/>

                                <Route
                                    path={'/matreya'}
                                    element={<MatreyaMoveFAQ/>}/>

                                <Route
                                    path="/news"
                                    element={<NewsView/>}/>

                                <Route
                                    path="/news/:q"
                                    element={<NewsItemView/>}/>

                                <Route
                                    path="/orderterms"
                                    element={<OrderTermsView/>}/>

                                <Route
                                    path="/productline/supplier/precellys"
                                    element={<PrecellysView/>}/>

                                <Route
                                    path="/productline/supplier/:id"
                                    element={<SupplierView/>}/>

                                <Route
                                    path={'/privacy'}
                                    element={<PrivacyView/>}/>

                                <Route
                                    path="/productpartners"
                                    element={<ProductPartnersView/>}/>

                                <Route
                                    path="/account/*"
                                    element={<AccountView/>}/>

                                {[
                                    '9003237',
                                    '9003286',
                                    '9003380',
                                    '9003381',
                                    '9004121',
                                    '9004329',
                                    '9004330',
                                ].map(fasKitCatalogNumber =>
                                    <Route
                                        key={fasKitCatalogNumber}
                                        path={`/product/${fasKitCatalogNumber}/:name?`}
                                        element={<Navigate to={fasKitUri} replace
                                    />}
                                />)}

                                <Route
                                    path={'/fas'}
                                    element={<Navigate to={fasKitUri} replace/>}/>

                                <Route
                                    path={'/faskit'}
                                    element={<Navigate to={fasKitUri} replace/>}/>

                                <Route
                                    path="/product/:id/:name?"
                                    element={<ProductView/>}/>

                                <Route
                                    path="/flipbook/:id?"
                                    element={<FlipbookView/>}/>

                                <Route
                                    path={'/psychedelics'}
                                    element={<PsychedelicsView/>}/>

                                <Route
                                    path={'/services'}
                                    element={<Navigate to={'/services/overview'} replace/>}/>

                                <Route
                                    path="/services/*"
                                    element={<ServicesView/>}/>

                                <Route
                                    path="/sitemap"
                                    element={<SitemapView/>}/>

                                <Route
                                    path="/staff"
                                    element={<Staff/>}/>

                                <Route
                                    path="/structuredefinitions"
                                    element={<StructureDefinitionsView/>}/>

                                <Route
                                    path={'/runthecaymancomparison'}
                                    element={<RunTheCaymanComparisonView/>}/>

                                <Route
                                    path={'/reverseTransfectionReporterAssays'}
                                    element={<Navigate to={'/search?raptas=RAP000288'} replace/>}/>

                                <Route
                                    path={'/style'}
                                    element={<StyleView/>}/>

                                <Route
                                    path={'/technicalservices'}
                                    element={<Navigate to={'/technicalsupport'} replace/>}/>

                                <Route
                                    path="/technicalsupport"
                                    element={<TechnicalSupportView/>}/>

                                <Route
                                    path="/test"
                                    element={<TestView/>}/>

                                <Route
                                    path="/componentShowcase"
                                    element={<ComponentShowcase/>}/>

                                <Route
                                    path={'/cancer'}
                                    element={<Navigate to={'/cancer/overview'} replace/>}/>

                                <Route
                                    path={'/cancer/*'}
                                    element={<CancerView/>}/>


                                <Route
                                    path={'/pcrassays'}
                                    element={<Navigate to={'/productline/supplier/pcrassays'} replace/>}/>



                                <Route
                                    path={'*'}
                                    element={<ErrorView/>}/>

                            </Routes>
                        </Row>

                        <Footer/>
                    </div>

                </BrowserRouter>
            </Container>
        </div>
    );
}

export default withCookies(App);
